import React, { useEffect, useMemo } from 'react'
import useStoryblokApi from 'hooks/useStoryblokApi'
import C from './index.js'

export default function EditableTransformer ({ data, ...props }) {
  // const lang = useMemo(
  //   () => {
  //     const match = window?.location?.href?.match(/_storyblok_lang=(\w{2,7})/g)
  //     console.log('DEBUG LANG', match)
  //     return match ? match[1] : null
  //   },
  //   [window.location]
  // )

  const downloadsStories =
    useStoryblokApi({
      by_uuids: data.downloads?.join(','),
      version: 'draft' // todoj: should be enabled by default for editing!
      // starts_with: lang && `${lang}/` // use this to get translated downloads in editor DOESN'T WORK
    })?.stories || []

  const downloads = useMemo(
    () => downloadsStories.map(dl => ({ ...dl, component: dl.content })),
    [downloadsStories]
  )

  // // TODOJ: REMOVE => DEBUG ONLY
  // useEffect(
  //   () => {
  //     console.log('DEBUG', { lang, location: window.location, downloads })
  //   },
  //   [lang, window.location, downloads]
  // )

  return (
    <C
      data={{
        ...data,
        downloads
      }}
      {...props}
    />
  )
}
